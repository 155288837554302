<template>
  <div class="login-body">
    <div class="card login-panel p-fluid">
      <div class="login-panel-content">
        <Toast />
        <div class="grid">
          <div class="col-12 sm:col-6 md:col-6 logo-container">
            <img src="layout/images/logo-white.png" alt="masipas" />
            <div class="text-900 text-3xl font-medium mb-3">Redefinição de senha</div>
            <span class="guest-sign-in">Preencha os dados abaixo para efetuar a redefinição de senha</span>
          </div>

          <div class="col-12">
            <label>Nova senha</label>
            <div class="login-input">
              <InputText type="password" v-model.trim="form.newPassword" autocomplete="off"
                :class="{ 'p-invalid': submitted && !form.newPassword }" />
              <small class="p-error" v-if="submitted && !form.newPassword">Senha é obrigatória.</small>
            </div>
          </div>

          <div class="col-12">
            <label>Confirmar nova senha</label>
            <div class="login-input">
              <InputText type="password" v-model.trim="form.confirmPassword" autocomplete="off"
                :class="{ 'p-invalid': form.confirmPassword && form.confirmPassword != form.newPassword }" />
              <small class="p-error" v-if="form.confirmPassword && form.confirmPassword != form.newPassword">Senhas não
                conferem.</small>
            </div>
          </div>

          <div class="col-6">
            <Button label="Salvar" icon="pi pi-save" @click="save" />
          </div>

          <div class="col-6 flex align-items-center justify-content-center">
            <router-link to="/login" class="login-link" exact>Voltar para o login</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseService from '../services/BaseService';

export default {
  data() {
    return {
      submitted: false,
      form: {}
    }
  },
  methods: {
    async save() {
      this.submitted = true;

      if (!this.form.newPassword || this.form.newPassword != this.form.confirmPassword) {
        return;
      }

      const service = new BaseService('/password/reset');

      try {
        await service.save({ token: this.$route.query.token, newPassword: this.form.newPassword });
        this.$router.push('/login');
      } catch (error) {
        this.$toast.add({
          severity: "error",
          summary: "Erro ao salvar nova senha!",
          detail: error.response?.data?.message,
          life: 3000,
        });
      }
    }
  }
}
</script>
